import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class Audit extends React.Component {
	render() {
		const siteTitle = 'Audit'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Audit</h3>
								<p>
									{' '}
									<strong>Audit and Assurance</strong>
									<br />
									An audit or review is a legal requirement for some
									organisations..
								</p>
								<p>
									<strong>We act as independent auditors for:</strong>
									<ul>
										<li>Commercial organisations.</li>
										<li>
											Not-for-profit organisations including charities, schools,
											kindergartens, churches, missions &amp; clubs whether
											being a company limited by guarantee, an incorporated
											association or an unincorporated association.
										</li>
										<li>Trust accounts</li>
									</ul>
								</p>
								<p>
									An audit can add strength to financial reports for lending
									bodies. Our staff have many years of experience in all these
									areas. For all your audit and assurance needs contact WSC
									Group to discuss your organisations’ needs.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Audit

export const AuditPageQuery = graphql`
	query AuditPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
